import AdminLogin from "./../../../Forms/Login/AdminLogin/AdminLogin";
import FacultyLogin from "./../../../Forms/Login/FacultyLogin/FacultyLogin";
import React, { Component } from "react";
import Overlay from "./Overlay/Overlay";
import styles from "./../HomePage.module.css";
class HomeContainer extends Component {
  state = {
    rightPanelActive: true,
  };

  handleClickFacultyButton = () =>
    this.setState({
      rightPanelActive: false,
    });

  handleClickAdminButton = () =>
    this.setState({
      rightPanelActive: true,
    });

  render() {
    const { handleClickFacultyButton, handleClickAdminButton } = this;
    const { rightPanelActive } = this.state;
    return (
      <div>
        <div
          className={`${styles.Container} ${
            rightPanelActive ? `${styles.Right_Panel_Active}` : ``
          }`}
        >
          <FacultyLogin tab={this.state.rightPanelActive} />
          <AdminLogin tab={this.state.rightPanelActive} />
          <Overlay
            handleClickFacultyButton={handleClickFacultyButton}
            handleClickAdminButton={handleClickAdminButton}
            tab={this.state.rightPanelActive}
          />
        </div>
      </div>
    );
  }
}

export default HomeContainer;
