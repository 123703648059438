import React, { Component } from "react";
import { Logo } from "./../../../../../static/svg";
import styles from "./../../HomePage.module.css";
class Overlay extends Component {
  render() {
    const {
      handleClickAdminButton,
      handleClickFacultyButton,
      tab,
    } = this.props;
    return (
      <div className={styles.Overlay_Container}>
        <div className={styles.Overlay}>
          <div className={`${styles.Overlay_Panel} ${styles.Overlay_Left}`}>
            <h1>Admin Login</h1>
            <p className={styles.Overlay_Description}>
              <Logo className={styles.Logo} />
            </p>
            <button
              className={`${styles.Ghost} ${styles.Form_Button}`}
              onClick={handleClickFacultyButton}
              tabIndex={tab ? "" : "-1"}
            >
              Admin Login
            </button>
          </div>
          <div className={`${styles.Overlay_Panel} ${styles.Overlay_Right}`}>
            <h1>Faculty Login</h1>
            <p className={styles.Overlay_Description}>
              <Logo className={styles.Logo} />
            </p>
            <button
              className={`${styles.Ghost} ${styles.Form_Button}`}
              onClick={handleClickAdminButton}
              tabIndex={tab ? "-1" : ""}
            >
              Faculty Login
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Overlay;

