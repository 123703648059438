import axios from "./../../api/axios";
import { SubmissionError } from "redux-form";

export const facProjectsEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/project/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};

export const facPastExperienceEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/pastexp/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};

export const facAchievementsEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/achievement/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};

export const facPatentsEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/patent/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};

export const facPersonalDetailsEdit = (data, token) => {
  return () => {
    return axios
      .patch(`/faculty/personaldetail`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
export const facPublicationsEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/publication/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};

export const facExpertiseInEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/expertise/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};

export const facResearchEdit = (data, token, id) => {
  return () => {
    return axios
      .put(`/faculty/research/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};

export const facAcademicQualifyEdit = (data, token) => {
  return () => {
    return axios
      .patch(`/faculty/acadqualify`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
