import React from "react";
import styles from "./InputRender.module.css";

export const renderField = ({
  meta: { touched, error },
  label,
  id,
  placeholder,
  type,
  autoComplete,
  input,
  required,
  tabIndex,
}) => (
  <div>
    <label className={styles.Input_Label} htmlFor={id}>
      {label}
      {required && <span className={styles.Input_Required}>*</span>}
    </label>
    <br />
    <input
      className={styles.Input}
      id={id}
      {...input}
      autoComplete={autoComplete}
      placeholder={placeholder}
      type={type}
      tabIndex={tabIndex}
    />
    <div className={styles.ErrorDiv}>
      {touched && error && <span className={styles.ErrorDes}>{error}</span>}
    </div>
  </div>
);

export const renderTextArea = ({
  meta: { touched, error },
  label,
  placeholder,
  id,
  type,
  required,
  input,
}) => (
  <div>
    <label className={styles.Input_Label} htmlFor={id}>
      {label}
      {required && <span className={styles.Input_Required}>*</span>}
    </label>

    <br />
    <textarea
      className={`${styles.Input} ${styles.Input_Textarea}`}
      id={id}
      {...input}
      placeholder={placeholder}
      type={type}
      rows="5"
      cols="20"
    />
    <div className={styles.ErrorDiv}>
      {touched && error && <span className={styles.ErrorDes}>{error}</span>}
    </div>
  </div>
);

export const acadYear = [
  { "2000-01": "2000-01" },
  { "2001-02": "2001-02" },
  { "2002-03": "2002-03" },
  { "2003-04": "2003-04" },
  { "2004-05": "2004-05" },
  { "2005-06": "2005-06" },
  { "2006-07": "2006-07" },
  { "2007-08": "2007-08" },
  { "2008-09": "2008-09" },
  { "2009-10": "2009-10" },
  { "2010-11": "2010-11" },
  { "2011-12": "2011-12" },
  { "2012-13": "2012-13" },
  { "2013-14": "2013-14" },
  { "2014-15": "2014-15" },
  { "2015-16": "2015-16" },
  { "2016-17": "2016-17" },
  { "2017-18": "2017-18" },
  { "2018-19": "2018-19" },
  { "2019-20": "2019-20" },
  { "2020-21": "2020-21" },
  { "2021-22": "2021-22" },
  { "2022-23": "2022-23" },
  { "2023-24": "2023-24" },
  { "2024-25": "2024-25" },
  { "2025-26": "2025-26" },
  { "2026-27": "2026-27" },
  { "2027-28": "2027-28" },
  { "2028-29": "2028-29" },
  { "2029-30": "2029-30" },
  { "2030-31": "2030-31" },
  { "2031-32": "2031-32" },
  { "2032-33": "2032-33" },
  { "2033-34": "2033-34" },
  { "2034-35": "2034-35" },
  { "2035-36": "2035-36" },
  { "2036-37": "2036-37" },
  { "2037-38": "2037-38" },
  { "2038-39": "2038-39" },
  { "2039-40": "2039-40" },
  { "2040-41": "2040-41" },
  { "2041-42": "2041-42" },
  { "2042-43": "2042-43" },
  { "2043-44": "2043-44" },
  { "2044-45": "2044-45" },
  { "2045-46": "2045-46" },
  { "2046-47": "2046-47" },
  { "2047-48": "2047-48" },
  { "2048-49": "2048-49" },
  { "2049-50": "2049-50" },
  { "2050-51": "2050-51" },
  { "2051-52": "2051-52" },
  { "2052-53": "2052-53" },
  { "2053-54": "2053-54" },
  { "2054-55": "2054-55" },
  { "2055-56": "2055-56" },
  { "2056-57": "2056-57" },
  { "2057-58": "2057-58" },
  { "2058-59": "2058-59" },
  { "2059-60": "2059-60" },
  { "2060-61": "2060-61" },
  { "2061-62": "2061-62" },
  { "2062-63": "2062-63" },
  { "2063-64": "2063-64" },
  { "2064-65": "2064-65" },
  { "2065-66": "2065-66" },
  { "2066-67": "2066-67" },
  { "2067-68": "2067-68" },
  { "2068-69": "2068-69" },
  { "2069-70": "2069-70" },
  { "2070-71": "2070-71" },
  { "2071-72": "2071-72" },
  { "2072-73": "2072-73" },
  { "2073-74": "2073-74" },
  { "2074-75": "2074-75" },
  { "2075-76": "2075-76" },
  { "2076-77": "2076-77" },
  { "2077-78": "2077-78" },
  { "2078-79": "2078-79" },
  { "2079-80": "2079-80" },
  { "2080-81": "2080-81" },
  { "2081-82": "2081-82" },
  { "2082-83": "2082-83" },
  { "2083-84": "2083-84" },
  { "2084-85": "2084-85" },
  { "2085-86": "2085-86" },
  { "2086-87": "2086-87" },
  { "2087-88": "2087-88" },
  { "2088-89": "2088-89" },
  { "2089-90": "2089-90" },
  { "2090-91": "2090-91" },
  { "2091-92": "2091-92" },
  { "2092-93": "2092-93" },
  { "2093-94": "2093-94" },
  { "2094-95": "2094-95" },
  { "2095-96": "2095-96" },
  { "2096-97": "2096-97" },
  { "2097-98": "2097-98" },
  { "2098-99": "2098-99" },
  { "2099-00": "2099-00" },
];

export const renderDropdown = ({
  meta: { touched, error },
  id,
  label,
  placeholder,
  userOption,
  input,
  required,
  readOnly,
}) => (
  <div>
    <label className={styles.Input_Label} htmlFor={id}>
      {label}
      {required && <span className={styles.Input_Required}>*</span>}
    </label>
    <br />
    <select
      {...input}
      placeholder={placeholder}
      id={id}
      className={styles.Input}
      disabled={readOnly ? true : false}
    >
      <option value="" className={styles.Input_Option}>
        Select
      </option>
      {userOption.map((userOptions) => {
        return Object.keys(userOptions).map((e) => {
          return (
            <option value={userOptions[e]} key={e}>
              {e}
            </option>
          );
        });
      })}
    </select>
    <div className={styles.ErrorDiv}>
      {touched && error && <span className={styles.ErrorDes}>{error}</span>}
    </div>
  </div>
);

export class FieldFileInput extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const {
      input: { onChange },
    } = this.props;
    let fileList = [];
    Object.keys(e.target.files).forEach((el) => {
      fileList.push(e.target.files[el]);
    });
    onChange(fileList);
  }

  render() {
    const {
      meta: { touched, error, dirty },
      label,
      required,
      accept,
      id,
      multiple,
    } = this.props;
    const {
      input: { value },
    } = this.props;
    const fileInputKey = value ? value.name : +new Date();
    return (
      <div className={styles.Input_File}>
        <label className={styles.Input_Label}>
          {label}
          {required && <span className={styles.Input_Required}>*</span>}
        </label>
        <div className={styles.Input_File_Container}>
          <input
            className={styles.File_Input}
            id={id}
            type="file"
            key={fileInputKey}
            accept={accept}
            ref={(ref) => (this.fileInput = ref)}
            multiple={multiple}
            onChange={this.onChange}
          />
          <label className={styles.Input_Label} htmlFor={id}>
            <div
              className={styles.File_Label}
              title={value && value[0] && value[0].name}
            >
              {value
                ? value.length > 1
                  ? `${value.length} Selected`
                  : value[0]
                  ? value[0].name
                  : "Upload"
                : "Upload"}
            </div>
          </label>
          <div className={styles.ErrorDiv}>
            {(dirty || touched) && error && (
              <span className={styles.ErrorDes}>{error}</span>
            )}
          </div>
        </div>
      </div>
    );
  }
}
