import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "./../../../../store/action/index";
import { renderField } from "./../../InputRender/InputRender";
import { SmallLoader } from "./../../../UI/Loader/Loader";
import overlayStyles from "./../../../Views/HomePage/HomePage.module.css";
import styles from "./../Login.module.css";

const adminLogin = (props) => {
  const { handleSubmit, submitting, error, pristine, tab } = props;

  const submit = (values) => {
    return props.login(
      {
        email: values.email,
        password: values.password,
      },
      "/admin",
      "AdminLogin"
    );
  };

  return (
    <div
      className={`${overlayStyles.Form_Container} ${overlayStyles.Admin_Container}`}
    >
      <form className={overlayStyles.Form} onSubmit={handleSubmit(submit)}>
        <h1 className={styles.Login_Title}>
          <em>Admin Login</em>
        </h1>
        <Field
          name="email"
          type="email"
          component={renderField}
          label="Email"
          placeholder="Email ID"
          id="AdminLoginEmail"
          tabIndex={tab ? "-1" : ""}
        />
        <Field
          name="password"
          type="password"
          component={renderField}
          label="Password"
          placeholder="Password"
          id="AdminLoginId"
          autoComplete="off"
          tabIndex={tab ? "-1" : ""}
        />
        <div className={styles.Login_Error}>
          {error && <strong>{error}</strong>}
        </div>
        <div className={styles.Login_Buttons}>
          {submitting ? (
            <SmallLoader />
          ) : (
            <>
              <button
                className={styles.Login_Button}
                type="submit"
                disabled={submitting || pristine}
                tabIndex={tab ? "-1" : ""}
              >
                Login
              </button>
            </>
          )}
        </div>
        <Link
          className={styles.Admin_Link}
          to="/forgetpassword/admin"
          tabIndex={tab ? "-1" : ""}
        >
          Forget Password?
        </Link>
      </form>
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (
    !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@((somaiya\.edu)$)/i.test(
      values.email
    )
  ) {
    errors.email = "Invalid Email Address";
  }
  return errors;
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data, link, form) => dispatch(action.login(data, link, form)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: "AdminLogin",
    validate,
  })(adminLogin)
);
