import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

const footer = (props) => {
  return (
    <div className={styles.footerContainer}>
      <a
        href="https://kjsieit.somaiya.edu/en"
        rel="noopener noreferrer"
        target="_blank"
      >
        KJSIEIT
      </a>
      <Link to="/support">Support</Link>
      <a
        href="https://somaiya.edu/en"
        rel="noopener noreferrer"
        target="_blank"
      >
        Somaiya Vidyavihar
      </a>
    </div>
  );
};
export default footer;
