import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./store/reducer/auth";
import thunk from "redux-thunk";
import history from "./history";
import { ErrorBoundary } from "react-error-boundary";

import Header from "./components/Header/Header";
import App from "./containers/App";

import "./index.css";

const enhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
});

const store = createStore(rootReducer, enhancers(applyMiddleware(thunk)));

ReactDOM.render(<Header />, document.getElementById("header"));

const happy = String.fromCodePoint(0x1f600);
const mad = String.fromCodePoint(0x1f621);

console.log(happy);
console.log(
  `%cHello!\nWelcome to ${window.location.hostname}`,
  "color:springgreen;font-family:cursive;font-size:2rem"
);
console.log(
  "%cNo\nPeeping\nor\nMischiefs",
  "color:red;background-color:purple;font-family:cursive;font-size:3rem;font-weight:bolder,text-align:center"
);
console.log(mad);
function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <p>Something went wrong please contact the website owner</p>
      <pre>{error.message}</pre>
    </div>
  );
}
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <App />
        </ErrorBoundary>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
