import React from "react";

import clgLogo from "./../../static/svg/KJSIEIT_LOGO_BLACK.svg";
import trustLogo from "./../../static/svg/TRUST_LOGO.svg";
import history from "./../../history";
import styles from "./Header.module.css";
const header = () => {
  return (
    <div className={styles.headerContainer}>
      <img
        className={styles.Navigate}
        src={clgLogo}
        alt="College Logo"
        onClick={() => {
          history.push("/");
        }}
      />
      <img src={trustLogo} alt="Trust Logo" />
    </div>
  );
};
export default header;
