import axios from "./../../api/axios";
import { reset, SubmissionError } from "redux-form";

export const personal = (data, token, form) => {
  return (dispatch) => {
    return axios
      .post(`/faculty/personaldetail`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(reset(form));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
export const academicQualify = (data, token, form) => {
  return (dispatch) => {
    return axios
      .post(`/faculty/acadqualify`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(reset(form));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};

export const facAddAcad = (AddEdu, data, token, form) => {
  return (dispatch) => {
    return axios
      .post(`/faculty/onequalify/${AddEdu}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(reset(form));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
export const pastExperience = (data, token, form) => {
  return (dispatch) => {
    return axios
      .post(`/faculty/pastexp`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(reset(form));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
export const achieve = (data, token, form) => {
  return (dispatch) => {
    return axios
      .post(`/faculty/achievement`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(reset(form));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
export const memProf = (data, token, form) => {
  return (dispatch) => {
    return axios
      .post(`/faculty/memprof`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(reset(form));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
export const memCom = (data, token, form) => {
  return (dispatch) => {
    return axios
      .post(`/faculty/memcomm`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(reset(form));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
export const acId = (data, token, form) => {
  return (dispatch) => {
    return axios
      .post(`/faculty/id`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(reset(form));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
export const expert = (data, token, form) => {
  return (dispatch) => {
    return axios
      .post(`/faculty/expertise`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(reset(form));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
export const facResearch = (data, token, form) => {
  return (dispatch) => {
    return axios
      .post(`/faculty/research`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(reset(form));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
export const facPatents = (data, token, form) => {
  return (dispatch) => {
    return axios
      .post(`/faculty/patent`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(reset(form));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
export const facProjects = (data, token, form) => {
  return (dispatch) => {
    return axios
      .post(`/faculty/project`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(reset(form));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
export const facPublications = (data, token, form) => {
  return (dispatch) => {
    return axios
      .post(`/faculty/publication`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(reset(form));
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};
