import axios from "./../../api/axios";
import history from "./../../history";
import { reset, SubmissionError } from "redux-form";

export const forgetPass = (data, link, form) => {
  return (dispatch) => {
    return axios
      .post(`/${link}/forgetpassword`, data)
      .then((res) => {
        dispatch(reset(form));
        setTimeout(() => {
          history.push("/");
        }, 1000);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};

export const verifyPassword = (data, link, form) => {
  return (dispatch) => {
    return axios
      .post(`/${link}/verifypassword`, data)
      .then((res) => {
        dispatch(reset(form));
        setTimeout(() => {
          history.push("/");
        }, 1000);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};

export const facSignup = (data, form) => {
  return (dispatch) => {
    return axios
      .post("/faculty/signup", data)
      .then((res) => {
        dispatch(reset(form));
        history.push("/");
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};

export const facProfilePic = (data, token) => {
  return () => {
    return axios
      .post("/faculty/profileurl", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
        throw new SubmissionError({
          _error: err.response.data.message,
        });
      });
  };
};

export const deleteData = (url, id, token) => {
  return () => {
    return axios
      .delete(`/faculty/${url}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
        console.log(err.response.data);
      });
  };
};

export const getFile = (url, token) => {
  return () => {
    axios
      .post(
        "/faculty/files",
        {
          filePath: url,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        window.open(window.URL.createObjectURL(res.data));
      })
      .catch((err) => {
        const fr = new FileReader();
        fr.onload = () => {
          console.log(JSON.parse(fr.result));
        };
        fr.readAsText(err.response.data);
        window
          .open(window.URL.createObjectURL(err.response.data))
          .document.write("<h1>File Not Found</h1>");
      });
  };
};
